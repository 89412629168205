<template lang="pug">
	extends ./table.pug

	block tableTH
		template(v-slot:cell(createdAt)='data')
			p.mb-2.b3(v-if="data.item.id") {{ data.item.id }}
			p.mb-0.b4.text-grey.text-nowrap(v-if="data.item.createdAt") {{ data.item.createdAt | dateDDMMMYYYY }}

		template(v-slot:cell(project)='data')
			router-link.b3.text-primary(v-if='data.item.plan && data.item.plan.project' :title='data.item.plan.project.title' :to='{name: "project_dashboard", params: {id: data.item.project.id}}' target='_blank') {{ data.item.plan.project.title }}
			router-link.b3.text-primary(v-else-if='data.item.project' :to='{name: "project_dashboard", params: {id: data.item.project.id}}' target='_blank') {{ data.item.project.title }}

		template(v-slot:cell(subscription)='data')
			router-link.mb-0.b3.text-primary.text-nowrap(v-if='data.item.subscription && !data.item.project.isNeuro()' :to='{name: "project_followers", params: {id: data.item.project.id}, query: {id: data.item.subscription.id}}' target='_blank') {{ data.item.subscription.id }}
			router-link.mb-0.b3.text-primary.text-nowrap(v-if='data.item.subscription && data.item.project.isNeuro()' :to='{name: "audience", params: {id: data.item.project.id}, query: {id: data.item.subscription.id}}' target='_blank') {{ data.item.subscription.id }}
			p.mb-0.b4.text-grey.text-nowrap(v-if='data.item.user && data.item.user.id') {{ data.item.user.tgUsername }}

		template(v-slot:cell(account)='data')
			p.b3.mb-0 {{ data.item.account.id }}

		template(v-slot:cell(profitAmount)='data')
			p.mb-0.b3.text-nowrap(v-if='data.item.info.toMerchant' v-html="data.item.info.toMerchant.priceWithCurrency()")
			p.mb-0.b3.text-nowrap(v-else-if='data.item.info.toMerchantRUB' v-html="data.item.info.toMerchantRUB.priceWithCurrency()")

		template(v-slot:cell(status)='data')
			p.b3.mb-0.vuetable__status(v-if='data.item' :class="statusVariant(data.item)" :id="!isMobile ? `status-${data.item.id}` : ''" @click="isMobile ? $bvModal.show(`status-${data.item.id}`) : ''")
				span.b3(v-if='data.item.statusO') {{ data.item.statusO[`title${locale.toUpperCase()}`] }}
				span.b3(v-else) {{ data.item.status }}

			modal-tooltip(
				v-if='data.item.errorMessage != null'
				:id="`status-${data.item.id}`"
				:text="data.item.errorMessage"
				:textMobile="data.item.errorMessage"
			)
</template>

<script>
import { bus } from '@/main.js';
import { mapGetters, mapActions } from 'vuex';
import TableMixin from './TableMixin';
import TableSearch from './TableSearch';
import TablePagination from './TablePagination';
import TableShowMore from './TableShowMore';
import { locale } from '@/main';
import { STATUS } from '@/models/payment';

export default {
	name: 'FinanceIncome',
	mixins: [
		TableMixin
	],
	props: {
		role: {
			type: String,
			default: 'item'
		},
		filters: {
			type: Object,
			default: () => ({})
		},
		type: {
			type: String,
			default: null
		}
	},
	components 	: {
		TableSearch,
		TablePagination,
		TableShowMore
	},
	data () {
		return {
			sortBy 			: 'createdAt',
			searchKeys 		: ['createdAt', 'profitAmount'],
			noLocalSorting: true,
			sorting			: (ctx) => {
				let data 		= {};

				data[`order[${ctx.sortBy}]`] = ctx.sortDesc ? 'desc' : 'asc';

				this.isbusy = true;
				this.getItems( data );
			},
			fields		: [],
			items 		: [],
			destroyed 	: false,
			emptyText	: bus.$t('project.noIncome'),
			locale		: locale
		}
	},

	mounted () {
		this.getFields();

		this.getItems = _.debounce( ( data = {} ) => {
			let f = this.parseFilters();

			if ( f.find ) {
				let s = _.find(STATUS, st => st.titleRU.toLowerCase() == f.find.toLowerCase() || st.titleEN.toLowerCase() == f.find.toLowerCase())
				if ( s && s.id ) f.find = s.id;
			}

			this.get(_.extend(f, {page: this.currentPage, itemsPerPage: this.perPage}, data))
				.then( async (v) => {
					this.isbusy = false;
					if ( this.currentPage == 1 ) this.items = [];

					this.items = this.items.concat(v);
					this.$emit('onGetItems', {amount: this.items.length, type: 'payments'});

					let projects 	= [];
					let plans 		= [];
					let pl_ids 		= _.uniq(_.map(_.filter(this.items, ii => ii.plan), i => _.isString(i.plan) ? _.last(i.plan.split('/')) : i.plan.id));

					if ( this.destroyed ) return;

					if (pl_ids.length > 0 )
						plans 		= await this.getPlans({
							itemsPerPage: pl_ids.length,
							id: pl_ids
						})

					this.$store.commit('finance/setPaymentsParams', {projects, plans, accounts: this.accounts})
				})
				.catch( v => this.isbusy = false );
		}, 500);

		bus.$on('tableFinanceIncomeExport', this.exportCSV);
		bus.$on('tableFinanceIncomeRefresh', (query) => {
			this.searching(query);
		});

		this.searching();
	},
	created () {
		this.searching = _.debounce( ( query ) => {
			this.filters.orSearch_query = query != '' ? {id: query} : null;
			this.sorting(this);
		}, 400);
	},
	destroyed () {
		this.destroyed = true;
		bus.$off('tableFinanceIncomeRefresh');
		bus.$off('tableFinanceIncomeExport');
		this.$store.commit('finance/setIncomeTotal', 0);
	},
	computed : {
		...mapGetters({
			totalItems	: 'finance/incomeTotal',
			isPending 	: 'finance/isPendingIncome',
			projects   	: 'project/shortList',
			accounts	: 'finance/accounts',
		})
	},
	methods : {
		...mapActions({
			get 		: 'finance/incomeShort',
			getProjects	: 'project/shortList',
			getPlans	: 'plans/plansPlain',
			csvPayments : 'finance/paymentsCSV',
		}),

		localSorting(ctx) {},

		exportCSV() {
			this.csvPayments(this.parseFilters());
		},

		statusVariant (item) {
			if ( item.isRequested() || item.isChecked() ) return "text-orange";
			if ( item.isRefund() ) return "text-orange";
			if ( item.isPaid() ) return "text-success";
			if ( item.isError() ) return "text-error";

			return '';
		},
		getFields () {
			this.fields = [
				{
					key 		: 'createdAt',
					label 		: this.$t('finance.incomeOne'),
					tdClass 	: 'align-middle',
					thClass 	: ''
				},
				{
					key 		: 'project',
					label 		: this.$t('h1.project'),
					tdClass 	: 'align-middle',
					thClass 	: ''
				},
				{
					key 		: 'subscription',
					label 		: this.$t('finance.subscription'),
					tdClass 	: 'align-middle',
					thClass 	: ''
				},
				{
					key 		: 'account',
					label 		: this.$t('finance.bankAccount'),
					tdClass 	: 'align-middle',
					thClass 	: ''
				},
				{
					key 		: 'profitAmount',
					label 		: this.$t('forms.sum'),
					tdClass 	: 'align-middle',
					thClass 	: ''
				},
				{
					key 		: 'status',
					label 		: this.$t('project.status'),
					tdClass 	: 'align-middle',
					thClass 	: ''
				}
			];
		}
	}
}
</script>
